:root {
  --global-font-family: 'Manrope', -apple-system, BlinkMacSystemFont,
    'Helvetica Neue', sans-serif;
  --global-font-weight-normal: 500;
  --global-font-weight-bold: 800;
  --global-color-main: #fff;
  --global-color-main-dark: #1d1d1d;
  --global-color-accent: rgb(231, 157, 157);
  --global-color-accent-dark: rgb(201, 90, 90);
  --global-color-muted: rgb(240, 207, 207);
}

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: var(--global-font-family);
  font-weight: var(--global-font-weight-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  font-family: var(--global-font-family) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6 {
  font-family: var(--global-font-family) !important;
  font-weight: 800 !important;
  color: var(--global-color-main-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  transition: all 0.25s ease-in-out;
  font-weight: 800;
}

@media screen and (max-width: 768px) {
  .button {
    font-size: 12px;
    padding: 0 16px;
    line-height: 40px;
  }
}

.button_main {
  background-color: #fff;
  color: var(--global-color-main-dark);
}

.button_accent {
  background-color: var(--global-color-accent);
  color: var(--global-color-main-dark);
}

.button_accent:hover,
.button_accent:focus {
  background-color: var(--global-color-accent-dark);
}

.uk-icon svg path,
.uk-icon svg circle {
  stroke: var(--global-color-accent) !important;
}

.uk-section-muted {
  background-color: var(--global-color-muted);
}

.uk-text-main {
  color: var(--global-color-main-dark);
}

.site-header__text {
  white-space: nowrap;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .site-header__text {
    font-size: 14px;
  }
}
