.description {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .arrow {
    margin-bottom: -20px !important;
  }
  .title {
    font-size: 24px;
  }
}

@media screen and (min-width: 960px) {
  .description {
    font-size: 24px !important;
  }
}
